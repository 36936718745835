/* eslint-disable */
var defaultConfig = {
  expires: "1d",
  path: "; path=/",
  domain: "",
  secure: "",
  sameSite: "; SameSite=Lax",
};

export default {
  get: function(key) {
    var value =
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;)\\s*" +
              encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") +
              "\\s*\\=\\s*([^;]*).*$)|^.*$"
          ),
          "$1"
        )
      ) || null;

    if (
      value &&
      value.substring(0, 1) === "{" &&
      value.substring(value.length - 1, value.length) === "}"
    ) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
    return value;
  },
  set: function(key, value, expireTimes, path, domain, secure, sameSite) {
    if (!key) {
      throw new Error("Cookie name is not find in first argument.");
    } else if (
      /^(?:expires|max\-age|path|domain|secure|SameSite)$/i.test(key)
    ) {
      throw new Error(
        'Cookie key name illegality, Cannot be set to ["expires","max-age","path","domain","secure","SameSite"]\t current key name: ' +
          key
      );
    }
    // support json object
    if (value && value.constructor === Object) {
      value = JSON.stringify(value);
    }
    var _expires = "";
    expireTimes =
      expireTimes == undefined ? defaultConfig.expires : expireTimes;
    if (expireTimes && expireTimes != 0) {
      switch (expireTimes.constructor) {
        case Number:
          if (expireTimes === Infinity || expireTimes === -1)
            _expires = "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
          else _expires = "; max-age=" + expireTimes;
          break;
        case String:
          if (/^(?:\d+(y|m|d|h|min|s))$/i.test(expireTimes)) {
            // get capture number group
            var _expireTime = expireTimes.replace(
              /^(\d+)(?:y|m|d|h|min|s)$/i,
              "$1"
            );
            // get capture type group , to lower case
            switch (
              expireTimes
                .replace(/^(?:\d+)(y|m|d|h|min|s)$/i, "$1")
                .toLowerCase()
            ) {
              // Frequency sorting
              case "m":
                _expires = "; max-age=" + +_expireTime * 2592000;
                break; // 60 * 60 * 24 * 30
              case "d":
                _expires = "; max-age=" + +_expireTime * 86400;
                break; // 60 * 60 * 24
              case "h":
                _expires = "; max-age=" + +_expireTime * 3600;
                break; // 60 * 60
              case "min":
                _expires = "; max-age=" + +_expireTime * 60;
                break; // 60
              case "s":
                _expires = "; max-age=" + _expireTime;
                break;
              case "y":
                _expires = "; max-age=" + +_expireTime * 31104000;
                break; // 60 * 60 * 24 * 30 * 12
              default:
                new Error('unknown exception of "set operation"');
            }
          } else {
            _expires = "; expires=" + expireTimes;
          }
          break;
        case Date:
          _expires = "; expires=" + expireTimes.toUTCString();
          break;
      }
    }
    document.cookie =
      encodeURIComponent(key) +
      "=" +
      encodeURIComponent(value) +
      _expires +
      (domain ? "; domain=" + domain : defaultConfig.domain) +
      (path ? "; path=" + path : defaultConfig.path) +
      (secure == undefined ? defaultConfig.secure : secure ? "; Secure" : "") +
      (sameSite == undefined
        ? defaultConfig.sameSite
        : sameSite
        ? "; SameSite=" + sameSite
        : "");
    return this;
  },
  remove: function(key, path, domain) {
    if (!key || !this.isKey(key)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(key) +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
      (domain ? "; domain=" + domain : defaultConfig.domain) +
      (path ? "; path=" + path : defaultConfig.path) +
      "; SameSite=Lax";
    return this;
  },
  isKey: function(key) {
    return new RegExp(
      "(?:^|;\\s*)" +
        encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") +
        "\\s*\\="
    ).test(document.cookie);
  },
  keys: function() {
    if (!document.cookie) return [];
    var _keys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
      .split(/\s*(?:\=[^;]*)?;\s*/);
    for (var _index = 0; _index < _keys.length; _index++) {
      _keys[_index] = decodeURIComponent(_keys[_index]);
    }
    return _keys;
  },
};
