<template>
  <div></div>
</template>

<script>
export default {
  name: "redir",
  created() {
    window.location.href = "https://posterilove.com";
  }
};
</script>
