export default function addGoogleGtag(gtagId) {
  if (!gtagId) return;
  // ? cdn
  const gtagCdnScript = document.createElement("script");
  gtagCdnScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  document.head.appendChild(gtagCdnScript);

  // ? run gtag
  const script = document.createElement("script");
  script.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "${gtagId}");
  `;

  gtagCdnScript.addEventListener("load", () => {
    document.head.appendChild(script);
  });
}
