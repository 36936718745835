import Vue from "vue";
import Vuex from "vuex";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { v4 as uuidv4 } from "uuid";
import cookies from "../plugins/cookies";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    respondId: null,
    interactionId: null,
    interaction: [],
    step: 0,
    owner: null,
    ownerId: null,
    isOldResponds: null,
    respond: [],
    avalableFontsList: [],
    customFonts: [],
    activePopup: "",
    visiblePopup: false,
    popupData: null,
    loading: true,
    errorsList: [],
    interactionTitle: "",
    socialNetworkForSharing: "none",
    isSentRespond: false,
  },
  getters: {
    errorsList: ({ errorsList }) => errorsList,
    socialNetworkForSharing: ({ socialNetworkForSharing }) =>
      socialNetworkForSharing,
    interactionTitle: ({ interactionTitle }) => interactionTitle,
    interaction: ({ interaction }) => interaction,
    interactionId: ({ interactionId }) => interactionId,
    respondId: ({ respondId }) => respondId,
    isOldResponds: ({ isOldResponds }) => isOldResponds,
    respond: ({ respond }) => respond,
    step: ({ step }) => step,
    loading: ({ loading }) => loading,
    owner: ({ owner }) => owner,
    ownerId: ({ ownerId }) => ownerId,
    activeStep: ({ step, interaction }) => interaction[step],
    avalableFontsList: ({ avalableFontsList }) => avalableFontsList,
    customFonts: ({ customFonts }) => customFonts,
    activePopup: ({ activePopup }) => activePopup,
    visiblePopup: ({ visiblePopup }) => visiblePopup,
    popupData: ({ popupData }) => popupData,
    isSentRespond: ({ isSentRespond }) => isSentRespond,
  },
  mutations: {
    UPDATE_STEP(state, value) {
      state.step = value;
    },
    UPDATE_INTERACTION(state, value) {
      state.interaction = value;
    },
    UPDATE_INTERACTION_ID(state, value) {
      state.interactionId = value;
    },
    SET_NEW_RESPOND(state, respond) {
      state.respond = respond;
    },
    UPDATE_RESPOND(state, { data = {}, idx = null, step }) {
      if (idx === null) return;
      if (!state.respond[step]) state.respond[step] = [];
      state.respond[step][idx] = { ...data };
    },
    UPDATE_OWNER(state, value) {
      state.owner = value;
    },
    UPDATE_OWNER_ID(state, value) {
      state.ownerId = value;
    },
    UPDATE_AVALABLE_FONTS_LIST(state, value) {
      state.avalableFontsList.push(value);
    },
    UPDATE_CUSTOM_FONTS(state, value) {
      state.customFonts = value;
    },
    UPDATE_ACTIVE_POPUP(state, value) {
      state.activePopup = value;
    },
    UPDATE_VISIBLE_POPUP(state, value) {
      state.visiblePopup = value;
    },
    UPDATE_POPUP_DATA(state, value) {
      state.popupData = value;
    },
    VISIBLE_LOADING(state, value) {
      state.loading = value;
    },
    UPDATE_INTERACTION_TITLE(state, value) {
      state.interactionTitle = value;
    },
    UPDATE_SOCIAL_NETWORK_FOR_SHARING(state, value) {
      state.socialNetworkForSharing = value;
    },
    isSentRespond(state) {
      state.isSentRespond = true;
    },
    UPDATE_ERRORS_LIST(
      state,
      { idx, msg = "", itemsLength = 0, clear = false } = {}
    ) {
      if (clear) state.errorsList = new Array(itemsLength).fill("");
      state.errorsList.splice(idx, 0, msg);
    },
    setRespondId(state, id) {
      const oldRespondId = cookies.get(id);
      state.isOldResponds = Boolean(cookies.get(id));
      const newRespondId = uuidv4();
      if (!oldRespondId) cookies.set(id, newRespondId, "10min");
      state.respondId = oldRespondId ?? newRespondId;
    },
  },
  actions: {
    updateInteraction({ commit }, value) {
      commit("UPDATE_INTERACTION", value);
    },
    updateStep({ commit }, value) {
      commit("UPDATE_STEP", value);
    },
    updateRespond({ commit }, value) {
      commit("UPDATE_RESPOND", value);
    },
    updateInteractionId({ commit }, value) {
      commit("UPDATE_INTERACTION_ID", value);
    },
    updateOwner({ commit }, value) {
      commit("UPDATE_OWNER", value);
    },
    updateOwnerId({ commit }, value) {
      commit("UPDATE_OWNER_ID", value);
    },
    addToAvalable({ commit }, value) {
      commit("UPDATE_AVALABLE_FONTS_LIST", value);
    },
    updateCustomFonts({ commit }, value) {
      commit("UPDATE_CUSTOM_FONTS", value);
    },
    visibleLoading({ commit }, value) {
      commit("VISIBLE_LOADING", value);
    },
    updateInteractionTitle({ commit }, value) {
      commit("UPDATE_INTERACTION_TITLE", value);
    },
    updateSocialNetworkForSharing({ commit }, value) {
      commit("UPDATE_SOCIAL_NETWORK_FOR_SHARING", value);
    },
    showPopup(
      { commit },
      { clear = false, type = "", visible = false, data = null } = {}
    ) {
      if (clear) {
        commit("UPDATE_POPUP_DATA", null);
        commit("UPDATE_ACTIVE_POPUP", "");
        commit("UPDATE_VISIBLE_POPUP", false);
      }
      commit("UPDATE_POPUP_DATA", data);
      commit("UPDATE_ACTIVE_POPUP", type);
      commit("UPDATE_VISIBLE_POPUP", visible);
    },
    async validate({ commit, getters, dispatch }) {
      const widgets = getters["activeStep"].objects;
      const itemsLength = widgets.length;
      commit("UPDATE_ERRORS_LIST", { clear: true, itemsLength });
      for (let idx = 0; idx < itemsLength; idx++) {
        if (widgets[idx].name === "input-field") {
          const msg = await dispatch("checkField", idx);
          commit("UPDATE_ERRORS_LIST", { idx, msg });
        }
      }
      return getters["errorsList"].every((i) => !i);
    },
    checkField({ getters }, idx) {
      const respond = getters["respond"];
      const step = getters["step"];
      const widget = getters["activeStep"].objects[idx];
      const isMandatory = widget.configs.mandatory;
      const placeholder = widget.objects[1].text;
      const value = respond?.[step]?.[idx]?.actionData?.[placeholder] ?? "";
      // ? required field is empty
      if (!respond?.[step]?.[idx] && isMandatory && !value)
        return "mandatory field";
      // ? validation
      if (widget.configs.fieldType === "email") {
        if (!value) return "mandatory field";
        return isEmail(value) ? "" : "email is not valid ";
      }
      if (widget.configs.fieldType === "phone") {
        if (!value) return "mandatory field";
        return isMobilePhone(value) ? "" : "phone is not valid";
      }
      return "";
    },
  },
});
