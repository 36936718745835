import gql from "graphql-tag";

export const GET_ATTACHMENT = gql`
  query getAttachment($id: uuid = "") {
    upload_my_attachment(where: { id: { _eq: $id } }) {
      id
      name
      content
    }
  }
`;

export const GET_INTERACTION_DATA = gql`
  query($id: String = "") {
    my_interaction(where: { link: { _eq: $id } }) {
      content
      active
      id
      name
      favicon
      gtag_id
      user {
        email
        id
      }
    }
  }
`;

export const UPDATE_VIEWS_COUNTER = gql`
  mutation($id: String = "") {
    update_my_interaction(
      where: { link: { _eq: $id } }
      _inc: { view_counter: 1 }
    ) {
      affected_rows
    }
  }
`;

// export const GET_OLD_RESPOND = gql`
//   query getOldRespond($id: uuid!) {
//     respond(where: { id: { _eq: $id } }) {
//       content
//     }
//   }
// `;

export const UPDATE_RESPOND = gql`
  mutation updateRespond(
    $content: jsonb = ""
    $device: String = ""
    $id: uuid = ""
    $myInteractionId: uuid = ""
  ) {
    insert_respond(
      objects: {
        content: $content
        device: $device
        id: $id
        my_interaction_id: $myInteractionId
      }
      on_conflict: { update_columns: [content], constraint: answer_pkey }
    ) {
      affected_rows
    }
  }
`;
export const GET_CUSTOM_FONTS_BY_USER_EMAIL = gql`
  query MyQuery($email: String = "") {
    upload_my_font(where: { user: { email: { _eq: $email } } }) {
      content
      name
    }
  }
`;
export const GET_PROD_FONTS = gql`
  query MyQuery($name: String = "") {
    font(where: { name: { _eq: $name } }) {
      content
      name
    }
  }
`;
export const LOG = gql`
  mutation createLog(
    $userId: String = ""
    $action: String = ""
    $data: jsonb = ""
  ) {
    insert_data_log(
      objects: { user_id: $userId, data: $data, action: $action }
    ) {
      affected_rows
    }
  }
`;
export const INSERT_USER_ATTACHMENT = gql`
  mutation insertUserAttachments(
    $myInteractionId: uuid!
    $name: String!
    $size: Int!
    $content: String!
    $userId: uuid!
    $respondId: uuid!
  ) {
    insert_user_attachments(
      objects: {
        my_interaction_id: $myInteractionId
        name: $name
        size: $size
        user_id: $userId
        content: $content
        respond_id: $respondId
      }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_USER_ATTACHMENT = gql`
  mutation deleteUserAttachments($content: String!) {
    delete_user_attachments(where: { content: { _eq: $content } }) {
      affected_rows
    }
  }
`;
