<template>
  <div class="interaction">
    <transition name="fade">
      <div v-if="loading" class="back">
        <div ref="loadContainer" class="single6">
          <img v-if="loadedFavicon" :src="favicon" />
        </div>
      </div>
    </transition>
    <div v-if="error" class="error">{{ error }}</div>
    <keep-alive v-else>
      <div class="interaction-content-container">
        <components
          :is="activePopup"
          v-show="visiblePopup"
          :popup-data="popupData"
        />
        <components
          v-for="(w, idx) of intStepObejcts"
          :is="w.name | widgetNameFilter"
          class="content-container"
          :key="`${step}_${idx}`"
          :widgetIdx="idx"
          @hideLoader="hideLoader(idx)"
        />
        <!-- <div v-if="errorsList.some((i) => i)" class="input-field-errors">
          {{ errorsMsg }}
        </div> -->
      </div>
    </keep-alive>
    <!-- <socialSharingWidget /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setManifestData, getPWAImg } from "@/utils/manifest";
import {
  UPDATE_VIEWS_COUNTER,
  GET_INTERACTION_DATA,
  // GET_OLD_RESPOND,
  LOG,
} from "@/gql";
// import socialSharingWidget from "@/components/widgets/SocialSharing";
import addGoogleGtag from "@/utils/addGoogleGtag";
import { ungzip } from "pako";

export default {
  name: "Interaction",
  components: {
    // socialSharingWidget,
    textWidget: () => import("@/components/widgets/Text"),
    imageWidget: () => import("@/components/widgets/Image"),
    scaleWidget: () => import("@/components/widgets/Scale"),
    starsWidget: () => import("@/components/widgets/Stars"),
    imagePickerWidget: () => import("@/components/widgets/ImagePicker"),
    inputFieldWidget: () => import("@/components/widgets/InputField"),
    buttonWidget: () => import("@/components/widgets/Button"),
    likeDislikeWidget: () => import("@/components/widgets/LikeDislike"),
    videoWidget: () => import("@/components/widgets/Video"),
    // ? popups
    likeDislikePopup: () => import("@/components/popups/LikeDislike"),
    uploadPopup: () => import("@/components/popups/Upload"),
  },
  data() {
    return {
      loadArray: [],
      error: "",
      title: "",
      favicon: null,
      loadedFavicon: false,
    };
  },
  filters: {
    widgetNameFilter(name) {
      return name ? name + "-widget" : null;
    },
  },
  computed: {
    ...mapGetters([
      "activeStep",
      "step",
      "activePopup",
      "visiblePopup",
      "popupData",
      "loading",
      "respondId",
      "isOldResponds",
      // "errorsList",
    ]),
    errorsMsg() {
      if (Array.isArray(this.errorsList)) {
        for (let i of this.errorsList) {
          if (i) return i;
        }
      }
      return "";
    },
    intStepObejcts() {
      if (this.activeStep && this.activeStep.objects)
        return this.activeStep.objects;
      return [];
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  async created() {
    // ? fetch interaction data by id and old respond if it exists
    try {
      const id = this.$route.params.id;
      this.$store.commit("setRespondId", id);
      // if (this.isOldResponds) await this.getOldRespond();
      if (!this.isOldResponds) this.updateViewsCounter(id);
      const { data } = await this.$apollo.query({
        query: GET_INTERACTION_DATA,
        variables: { id },
      });
      const interaction = data.my_interaction[0];
      if (!interaction) {
        location.href = process.env.VUE_APP_MAIN_URL;
      } else if (!interaction.active) {
        this.error = "interaction is not activated";
        this.$store.dispatch("visibleLoading", false);
      } else this.onFetchedInteraction(interaction);
    } catch (e) {
      console.log(e);
      location.href = process.env.VUE_APP_MAIN_URL;
    }
  },
  methods: {
    updateViewsCounter(id) {
      this.$apollo.mutate({
        mutation: UPDATE_VIEWS_COUNTER,
        variables: { id },
      });
    },
    // getOldRespond() {
    //   this.$apollo
    //     .query({
    //       query: GET_OLD_RESPOND,
    //       variables: { id: this.respondId },
    //     })
    //     .then(({ data }) => {
    //       data?.respond?.[0]?.content && console.log(111);
    //       // this.$store.commit('SET_NEW_RESPOND', data.respond[0].content)
    //     });
    // },
    async onFetchedInteraction(interaction) {
      // respondId
      // ? update site title
      this.title = interaction.name;
      // ? set favicon
      // ? set loader image
      const favicon = document.getElementById("favicon");
      const img = document.createElement("img");
      img.src = getPWAImg("144", this.$route.params.id);
      await new Promise((resolve) => {
        img.onload = () => {
          this.favicon = favicon.href = img.src;
          this.loadedFavicon = true;
          resolve();
        };
        img.onerror = () => {
          this.loadedFavicon = true;
          this.favicon = favicon.href = "/favicon.png";
          resolve();
        };
      });

      setManifestData(this.favicon, this.title, this.$route.params.id);
      // ? add google gtag
      addGoogleGtag(interaction.gtag_id);
      // ? log
      this.$apollo.mutate({
        mutation: LOG,
        variables: {
          userId: interaction.user.id,
          action: "interaction visit",
          data: {
            interaction_id: interaction.id,
            interaction_name: interaction.name,
          },
        },
      });
      const content = await this.fetchContent(interaction.content);
      // ? save interaction data
      this.$store.dispatch("updateInteractionId", interaction.id);
      this.$store.dispatch("updateInteractionTitle", interaction.name);
      this.$store.dispatch(
        "updateInteraction",
        content.filter((i) => i.objects)
      );
      this.$store.dispatch("updateOwner", interaction.user.email);
      this.$store.dispatch("updateOwnerId", interaction.user.id);
      this.watchLoading();
    },
    watchLoading() {
      if (!this.intStepObejcts.length)
        this.$store.dispatch("visibleLoading", false);
      this.loadArray = [];
      for (let i = 0; i < this.intStepObejcts.length; i++)
        this.loadArray.push(false);
    },
    hideLoader(idx) {
      this.loadArray[idx] = true;
      const isLoaded = this.loadArray.every((i) => i);
      if (isLoaded)
        setTimeout(() => this.$store.dispatch("visibleLoading", false), 200);
    },
    async fetchContent(content) {
      return fetch(content)
        .then((res) => res.arrayBuffer())
        .then((buffer) =>
          JSON.parse(new TextDecoder("utf-8").decode(ungzip(buffer)))
        );
    },
  },
  watch: {
    step() {
      document.body.style.removeProperty("--background-image");
      this.watchLoading();
    },
  },
};
</script>

<style scoped>
.loading,
.error,
.interaction {
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 575px;
  height: 100vh;
  color: #1d2d36;
  font-size: 25px;
  font-weight: 600;
  margin: auto;
  transform-origin: top;
}
.loading,
.error {
  width: 100vw;
  font-weight: 600;
  background: #fff;
}
.interaction-content-container {
  position: relative;
  width: 320px;
  height: 575px;
  font-weight: normal;
  overflow: hidden;
  font-size: 16px;
}
.content-container {
  position: absolute;
}
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-leave-to {
  opacity: 0;
}
.back {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
}
.single6 {
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: single6 1.2s infinite ease-in;
  animation: single6 1.2s infinite ease-in;
}
.single6 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.input-field-errors {
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
}

@-webkit-keyframes single6 {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes single6 {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
@media screen and (min-width: 500px) {
  .interaction-content-container {
    background: #fff;
  }
  .interaction {
    height: fit-content;
    max-width: fit-content;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  }
}
@media screen and (max-width: 320px) {
  .loading,
  .error,
  .interaction {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
