import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Interaction from "@/views/Interaction.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id",
    name: "Interaction",
    component: Interaction
  },
  {
    path: "/",
    name: "Main",
    component: Main
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
