export function getPWAImg(size, link) {
  return `${process.env.VUE_APP_AUTH_SERVER}/api/icon/${size}/${link}/icon.png`;
}

export function setManifestData(favicon, title, id) {
  const size144 = getPWAImg("144", id);

  const myDynamicManifest = {
    background_color: "#000000",
    start_url: location.href,
    display: "standalone",
    icons: [
      {
        src: getPWAImg("48", id),
        sizes: "48x48",
        type: "image/png"
      },
      {
        src: getPWAImg("72", id),
        sizes: "72x72",
        type: "image/png"
      },
      {
        src: getPWAImg("96", id),
        sizes: "96x96",
        type: "image/png"
      },
      {
        src: size144,
        sizes: "144x144",
        type: "image/png"
      },
      {
        src: getPWAImg("168", id),
        sizes: "168x168",
        type: "image/png"
      },
      {
        src: getPWAImg("192", id),
        sizes: "192x192",
        type: "image/png"
      }
    ],
    name: title,
    short_name: title,
    theme_color: "#ffffff"
  };
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifest = URL.createObjectURL(blob);
  const head = document.querySelector("head");
  const link = document.createElement("link");
  link.setAttribute("rel", "manifest");
  link.setAttribute("href", manifest);
  // for apple
  const linkA = document.createElement("link");
  linkA.setAttribute("rel", "apple-touch-icon");
  linkA.setAttribute("href", getPWAImg("152", id));
  // for apple
  const meta = document.createElement("meta");
  meta.setAttribute("name", "msapplication-TileImage");
  meta.setAttribute("content", size144);

  head.appendChild(link);
  head.appendChild(linkA);
  head.appendChild(meta);
}
