import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import apolloProvider from "@/plugins/vue-apollo";
import VueMeta from "vue-meta";
// import VueSocialSharing from "vue-social-sharing";
import "./registerServiceWorker";
import "./registerServiceWorker";

Vue.use(VueMeta);
// Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount("#app");
